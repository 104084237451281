<template>
	<div id="air-transport">
		<div class="go-back">
			<router-link :to="'/plan'">
				<i class="fas fa-arrow-left"></i>&nbsp;&nbsp;
				Go back
			</router-link>
		</div>
		<div class="content-air-transport">
			<div class="description">
				<div class="flights ">
					<p class="title">Flights to Mongolia</p>
					<p class="text">Mongolia is connected through a number of direct flights to a number of destinations in Asia and Europe. Our national carrier Mongolian Airlines is flying from Chinggis Khaan International Airport several times per day.</p>
				</div>
				<div class="airlines">
					<p class="title">{{ getAirline.title }}</p>
					<div class="list-airlines">
						<a :href="item.link" target="_blank" v-for="(item, index) in getAirline.airLine" :key="index" rel="noopener noreferrer">
							<img :src="item.img" alt="air-line" class="mr-2">
						</a>
					</div>
				</div>
			</div>
			

			<div class="map-world">
				<mapWorld @getDetail="getDetailAirLine"/>
			</div>
		</div>
		
	</div>
</template>

<script>
import air01 from '../../assets/img/airline-01.jpg'
import air02 from '../../assets/img/airline-02.jpg'
import air03 from '../../assets/img/airline-03.jpg'
import air04 from '../../assets/img/airline-04.jpg'
import air05 from '../../assets/img/airline-05.jpg'
import air06 from '../../assets/img/airline-06.jpg'

//components
import mapWorld from './MapWorldSvg'

export default {
	name: "AirTransport",
	components : {
		mapWorld
	},
	data() {
		return {
			getAirline : [],
			dataAirLine : [
				{
					'title' : 'Berlin, Frankfurt',
					'airLine' : [
						{
							'img' : air01,
							'link' : 'https://www.miat.com/'
						}
					]
				},
				{
					'title' : 'Moscow, Russia',
					'airLine' : [
						{
							'img' : air01,
							'link' : 'https://www.miat.com/'
						},
						{
							'img' : air02,
							'link' : 'https://www.aeromongolia.mn/'
						}
					]
				},
				{
					'title' : 'Beijing',
					'airLine' : [
						{
							'img' : air01,
							'link' : 'https://www.miat.com/'
						},
						{
							'img' : air05,
							'link' : 'http://www.airchina.com.cn/en/'
						}
					]
				},
				{
					'title' : 'Seoul, Busan',
					'airLine' : [
						{
							'img' : air01,
							'link' : 'https://www.miat.com/'
						},
						{
							'img' : air03,
							'link' : 'https://www.koreanair.com'
						},
						{
							'img' : air04,
							'link' : 'https://en.airbusan.com/content/individual/?'
						}
					]
				},
				{
					'title' : 'Tokyo',
					'airLine' : [
						{
							'img' : air01,
							'link' : 'https://www.miat.com/'
						}
					]
				},
				{
					'title' : 'Hong Kong',
					'airLine' : [
						{
							'img' : air01,
							'link' : 'https://www.miat.com/'
						}
					]
				},
				{
					'title' : 'Bangkok',
					'airLine' : [
						{
							'img' : air01,
							'link' : 'https://www.miat.com/'
						}
					]
				},
				{
					'title' : 'Sydney',
					'airLine' : [
						{
							'img' : air01,
							'link' : 'https://www.miat.com/'
						}
					]
				}
			]
		}
	},
	mounted() {
		this.firstAirline();
	},
	methods: {
		firstAirline() {
			this.getAirline = this.dataAirLine[0]
		},
		getDetailAirLine(index) {
			this.getAirline = this.dataAirLine[index]
		}
	}
};
</script>

<style lang="scss" scoped>
	#air-transport {
		width: 100%;
		height: 100%;
		padding: 0 5.71% 0;

		.go-back {
			width: 100%;
			height: 12.10%;
			display: flex;
			align-items: center;

			.router-link-active {
				color: #E13036;
				font-weight: bold;
			}
		}

		.content-air-transport {
			height: 87.90%;
			.description {
				display: flex;
				.flights {
					width: 50%;
					padding-right: 50px;
					.title {
						font-size: 18px;
						font-weight: bold;
					}

					.text {
						font-size: 12px;
					}

				}

				.airlines {
					padding: 25px;
					width: 50%;
					background-color: #E13036;
					color: #FFFFFF;

					.title {
						font-size: 18px;
						font-weight: bold;
					}
				}
			}
			

			.map-world {
				width: 100%
			}
		}

		@media only screen and (min-width: 1920px) {
            .go-back {
				.router-link-active {
					font-size: 20px;
				}
			}

			.content-air-transport {
				.description {
					.flights {
						.title {
							font-size: 50px;
						}

						.text {
							font-size: 15px;
						}

					}

					.airlines {
						padding: 25px;
						.title {
							font-size: 25px;
						}

						.list-airlines {
							img { 
								height: 70px;
							}
						}
					}
				}
			}
		}
	}
</style>